import useFetchCurrentUser from "./fetching/useFetchCurrentUser";
import useFetchMerchants from "./fetching/useFetchMerchants";
import useFetchPaymentInstruments from "./fetching/useFetchPaymentInstruments";
import useFetchRewardPoolEntries from "./fetching/useFetchRewardPoolEntries";
import useFetchRewardPoolEvents from "./fetching/useFetchRewardPoolEvents";
import useFetchRewardPools from "./fetching/useFetchRewardPools";
import useFetchUserData from "./fetching/useFetchUserData";
import useFetchUserRewards from "./fetching/useFetchUserRewards";

const useLazyServices = () => {
  useFetchMerchants();
  useFetchPaymentInstruments();
  useFetchCurrentUser();
  useFetchUserData();
  useFetchUserRewards();
  useFetchRewardPools();
  useFetchRewardPoolEntries();
  useFetchRewardPoolEvents();
};

export default useLazyServices;
