import React from "react";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { CardMedia, Stack, Typography } from "@mui/material";

import { ErrorAlert } from "~common/components/alerts";
import {
  DefaultButton,
  PrimaryButton,
} from "~common/components/controls/buttons";
import ExternalTextLink from "~common/components/links/ExternalTextLink";
import type { HighlightModal, HighlightStatus } from "~common/services";

import AppAdoptionButtons from "./AppAdoptionButtons";

type BaseHighlightModalProps = {
  contentId: string;
  error?: string;
  headingId: string;
  loading?: boolean;
  modal: HighlightModal;
  onClick: () => void;
  status: HighlightStatus;
};

const BaseHighlightModal: React.VFC<BaseHighlightModalProps> = ({
  contentId,
  error,
  headingId,
  loading,
  modal,
  onClick,
  status,
}) => (
  <Stack spacing={6}>
    <CardMedia
      image={modal.image}
      sx={{
        height: {
          zero: "220px",
          sm: "240px",
        },
      }}
    />

    <Stack spacing={6} sx={{ px: 6, pb: 8, textAlign: "center" }}>
      <Typography component="h3" id={headingId} variant="h1" sx={{ mb: -4 }}>
        {modal.header}
      </Typography>

      <Typography color="grey.600" id={contentId} variant="bodyRegular">
        {modal.subheader}
      </Typography>

      {status === "completed" || status === "unclaimable" ? (
        <DefaultButton disabled>
          {status === "completed"
            ? "Offer already claimed"
            : modal.cta_button.text}
        </DefaultButton>
      ) : (
        <>
          {modal.isAppAdoption ? (
            <AppAdoptionButtons
              hideIosButton={modal.isAndroidAppAdoption}
              hideAndroidButton={modal.isIosAppAdoption}
            />
          ) : (
            <PrimaryButton
              onClick={
                modal.cta_button.type === "claim_campaign" ? onClick : undefined
              }
              href={
                modal.cta_button.type === "link"
                  ? modal.cta_button.link
                  : undefined
              }
              target={modal.cta_button.type === "link" ? "_blank" : undefined}
              endIcon={
                modal.cta_button.type === "link" ? (
                  <OpenInNewIcon sx={{ width: 16, height: 16 }} />
                ) : undefined
              }
              loading={loading}
            >
              {modal.cta_button.text}
            </PrimaryButton>
          )}
        </>
      )}

      {error && <ErrorAlert>{error}</ErrorAlert>}

      {modal.subtext &&
        !modal.isAppAdoption &&
        (modal.subTextLink ? (
          <ExternalTextLink
            size="small"
            color="grey.500"
            eventName="Completed Modal Subtext Link Clicked"
            href={modal.subTextLink}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="linkSmall">{modal.subtext}</Typography>
              <OpenInNewIcon
                sx={{
                  height: 16,
                  width: 16,
                }}
              />
            </Stack>
          </ExternalTextLink>
        ) : (
          <Typography
            color="grey.600"
            variant="bodyRegular"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {modal.subtext}
          </Typography>
        ))}
    </Stack>
  </Stack>
);

export default BaseHighlightModal;
