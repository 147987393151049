import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { css, Global } from "@emotion/react";
import { InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, useTheme } from "@mui/material";

import MarqetaCard from "~common/components/marqeta/MarqetaCard";
import { embeddedMessenger } from "~common/utils/iframe-messaging";
import MobileAppEventBus from "~common/utils/mobile-app-messaging";
import { IS_DEV } from "~src/config";

import { selectCatchCard } from "../../store";

const CatchCard: React.FC = () => {
  const { palette } = useTheme();
  const [searchParams] = useSearchParams();
  const catchCard = useSelector(selectCatchCard);
  const [showInfo, setShowInfo] = useState(true);
  const referer = searchParams.get("referer") || document.referrer;
  const eventBus = new MobileAppEventBus({ overrideTargetToWindow: true });

  useEffect(() => {
    const showInfoHandler = () => setShowInfo(true);
    const hideInfoHandler = () => setShowInfo(false);

    eventBus.listenToEventFromMobile("CATCH_SHOW_INFO_BUTTON", showInfoHandler);
    eventBus.listenToEventFromMobile("CATCH_HIDE_INFO_BUTTON", hideInfoHandler);

    return () => {
      eventBus.off("CATCH_SHOW_INFO_BUTTON", showInfoHandler);
      eventBus.off("CATCH_HIDE_INFO_BUTTON", hideInfoHandler);
    };
  }, []);

  const handleInfoClick = () => {
    const messenger = embeddedMessenger(referer);
    messenger.dispatch("CATCH_ANYWHERE_CARD_INFO_CLICKED", {});
    messenger.destroy();
  };

  return (
    <Box
      sx={{
        maxWidth: 398,
        borderRadius: "6% / 9.71%",
        overflow: "hidden",
        position: "relative",
        "> div": {
          px: 3,
          pb: 4,
          pt: 5,
        },
        "> div > .MuiBox-root": {
          width: 105,
          height: 36,
          mb: 0.5,
          ml: 1,
        },
      }}
    >
      {searchParams.get("darkMode") === "true" && (
        <Global
          styles={css`
            body {
              background: ${palette.tints?.purple?.dark};
            }
          `}
        />
      )}

      <IconButton
        size="medium"
        aria-label="Catch card info"
        onClick={handleInfoClick}
        sx={{
          position: "absolute",
          top: 24,
          right: 14,
          color: palette.common.white,
          display: showInfo ? "block" : "none",
          "svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
            fontSize: 20,
          },
        }}
      >
        <InfoOutlined />
      </IconButton>

      <MarqetaCard token={catchCard?.marqeta_token || ""} isSandbox={IS_DEV} />
    </Box>
  );
};

export default CatchCard;
