import React from "react";

import { Stack } from "@mui/material";

import {
  HighlightWithState,
  RewardCampaignResponseData,
} from "~common/services";
import { CloseReason } from "~common/utils/modal-utils";

import Highlight from "./Highlight";
import HighlightIconSkeleton from "./HighlightIconSkeleton";

type HighlightListProps = {
  highlights: HighlightWithState[];
  loading: boolean;
  onClaim: (activeHighlight: HighlightWithState) => void;
  onRead: (activeHighlight: HighlightWithState) => void;
  onRedirect: (activeHighlight: HighlightWithState) => void;
  onClose: (
    activeHighlight: HighlightWithState,
    event: unknown,
    reason?: CloseReason
  ) => void;
  rewardCampaign: RewardCampaignResponseData | null;
  hideAppAdoptionHighlight?: boolean;
};

const HighlightList: React.VFC<HighlightListProps> = ({
  highlights,
  loading,
  onClaim,
  onRead,
  onRedirect,
  onClose,
  rewardCampaign,
  hideAppAdoptionHighlight,
}) => {
  highlights = [...highlights].sort((a, b) => {
    if (a.status !== b.status) {
      if (a.status === "completed") {
        return 1;
      }

      if (a.status === "read") {
        return b.status === "completed" ? -1 : 1;
      }

      if (a.status === "unread") {
        return -1;
      }
    }

    return 0;
  });

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      spacing={2}
      sx={{ display: "inline-flex" }}
    >
      {loading
        ? [...Array(3).keys()].map((i) => (
            <HighlightIconSkeleton key={`skeleton${i}`} />
          ))
        : highlights.map(
            (highlight) =>
              !(
                hideAppAdoptionHighlight &&
                highlight.initial_modal.isAppAdoption
              ) && (
                <Highlight
                  key={highlight.id}
                  highlight={highlight}
                  onClaim={onClaim}
                  onRead={onRead}
                  onRedirect={onRedirect}
                  onClose={onClose}
                  rewardCampaign={rewardCampaign}
                />
              )
          )}
    </Stack>
  );
};

export default HighlightList;
