import React from "react";

import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import CompactButton from "~common/components/controls/buttons/CompactButton";
import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";
import { CopyLinkButtonLabel } from "~common/components/markers";
import { WrappedTooltip } from "~common/components/tooltips";
import { REFERRAL_BONUS } from "~common/constants/bonuses";
import { useGetConstants } from "~common/services/constants";
import { formatMoney } from "~common/utils/money";
import LabeledSection from "~src/components/layout/LabeledSection";
import useCurrentUser from "~src/hooks/services/useCurrentUser";
import useShareReferralLink from "~src/hooks/services/useShareReferralLink";

const ReferralsSection: React.VFC = () => {
  const { currentUser, loading } = useCurrentUser();
  const { copyShareLink, linkCopied } = useShareReferralLink(
    "Account/ReferralsSection"
  );
  const { data: constants } = useGetConstants();
  const referralBonus = formatMoney(
    currentUser?.eligible_referral_bonus_amount || 2000,
    {
      dropCentsIfRound: true,
    }
  );
  const referralThreshold = constants?.purchase_amount_earned_referral_threshold
    ? formatMoney(constants.purchase_amount_earned_referral_threshold || 2000, {
        dropCentsIfRound: true,
      })
    : null;

  return (
    <LabeledSection heading="Referrals">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        color="grey.700"
        spacing={4}
      >
        <Typography variant="bodyLarge">
          Invite friends and earn{" "}
          {loading ? (
            <WrappedSkeleton
              variant="text"
              width={32}
              sx={{ display: "inline-flex" }}
            />
          ) : (
            referralBonus
          )}{" "}
          Catch Cash{" "}
          {referralThreshold && (
            <WrappedTooltip
              title={
                <>
                  You&apos;ll get {referralBonus} in Catch Cash when they spend{" "}
                  {referralThreshold} on their first Catch purchase.
                  They&apos;ll earn an extra ${REFERRAL_BONUS} on top of the
                  usual first order boost.
                </>
              }
              placement="bottom"
              sx={({ palette }) => ({
                verticalAlign: "middle",
                color: palette.grey[700],
                cursor: "default",
              })}
            >
              <InfoOutlinedIcon sx={{ height: 14, width: 14 }} />
            </WrappedTooltip>
          )}
        </Typography>

        <CompactButton
          onClick={copyShareLink}
          disabled={linkCopied !== null || loading}
          sx={({ palette }) => ({
            background: palette.common.white,
            minWidth: 100,
            whiteSpace: "nowrap",
          })}
        >
          <CopyLinkButtonLabel linkCopied={linkCopied} />
        </CompactButton>
      </Stack>
    </LabeledSection>
  );
};

export default ReferralsSection;
