import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Unclaim"
  >
    <path d="M0 0h24v24H0z" />
    <path
      d="M3.99 7 2.58 8.41 4.16 10H0v2h4.16l-1.59 1.59L3.99 15 8 11.01 3.99 7ZM18 11c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86 0 1.07-.34 2.04-.9 2.86.28.09.59.14.91.14Zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3Zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84ZM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3Z"
      fill="currentColor"
    />
  </svg>
);

export default SVG;
