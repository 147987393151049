import React from "react";

import { Stack } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import AndroidGooglePlayIcon from "~common/components/icons/apps/AndroidGooglePlayIcon";
import IosAppStoreIcon from "~common/components/icons/apps/IosAppStoreIcon";
import { useTracking } from "~common/tracking";
import { ADOPTION_URLS } from "~common/utils/adoption";

type AppAdoptionButtonsProps = {
  hideIosButton?: boolean;
  hideAndroidButton?: boolean;
};

const AppAdoptionButtons: React.VFC<AppAdoptionButtonsProps> = ({
  hideIosButton,
  hideAndroidButton,
}) => {
  const { trackEvent } = useTracking();

  return (
    <Stack spacing={4} direction="row">
      {!hideIosButton && (
        <PrimaryButton
          href={ADOPTION_URLS.ios}
          onClick={() =>
            trackEvent("Highlight Download on the App Store Clicked")
          }
          sx={({ palette, shape }) => ({
            "&.MuiButton-root": {
              width: hideAndroidButton ? "100%" : "50%",
              borderRadius: `${shape.borderRadius}px`,
              boxShadow: "none",
              ...(hideAndroidButton && {
                background: palette.tertiary.main,
              }),
              "&:after": {
                borderRadius: `${shape.borderRadius}px`,
              },
            },
            ".MuiBox-root": {
              display: "flex",
            },
          })}
        >
          <IosAppStoreIcon />
        </PrimaryButton>
      )}

      {!hideAndroidButton && (
        <PrimaryButton
          href={ADOPTION_URLS.android}
          onClick={() => trackEvent("Highlight Get it on Google Play Clicked")}
          sx={({ shape }) => ({
            "&.MuiButton-root": {
              width: hideIosButton ? "100%" : "50%",
              borderRadius: `${shape.borderRadius}px`,
              boxShadow: "none",
              "&:after": {
                borderRadius: `${shape.borderRadius}px`,
              },
            },
            ".MuiBox-root": {
              display: "flex",
            },
          })}
        >
          <AndroidGooglePlayIcon />
        </PrimaryButton>
      )}
    </Stack>
  );
};

export default AppAdoptionButtons;
