import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HighlightsResponseData, useGetHighlights } from "~common/services";
import { getUrlWithUserParams } from "~common/utils/navigation";
import { APP_ENVIRONMENT, APP_STAGE } from "~src/config";
import { selectHighlights } from "~src/store";
import {
  setHighlights,
  setHighlightsLoading,
} from "~src/store/slices/highlights-slice";

const useFetchHighlights = () => {
  const dispatch = useDispatch();
  const storedData = useSelector(selectHighlights);

  const handleResponse = (response: HighlightsResponseData) => ({
    highlights: response.highlights.map((highlight) => ({
      ...highlight,
      initial_modal: {
        ...highlight.initial_modal,
        isAppAdoption:
          highlight.initial_modal.cta_button.text === "app_download_buttons" ||
          highlight.initial_modal.cta_button.text ===
            "app_download_buttons_ios" ||
          highlight.initial_modal.cta_button.text ===
            "app_download_buttons_android",
        isIosAppAdoption:
          highlight.initial_modal.cta_button.text ===
          "app_download_buttons_ios",
        isAndroidAppAdoption:
          highlight.initial_modal.cta_button.text ===
          "app_download_buttons_android",
      },
      ...(highlight.completed_modal?.cta_button.link && {
        completed_modal: {
          ...highlight.completed_modal,
          cta_button: {
            ...highlight.completed_modal?.cta_button,
            link: getUrlWithUserParams(
              highlight.completed_modal?.cta_button.link,
              APP_ENVIRONMENT,
              APP_STAGE
            ),
          },
        },
      }),
    })),
  });

  const { data } = useGetHighlights({
    lazy: !!storedData,
    resolve: handleResponse,
  });

  useEffect(() => {
    if (!storedData && data?.highlights) {
      dispatch(setHighlights(data.highlights));
      dispatch(setHighlightsLoading(false));
    }
  }, [dispatch, data, storedData]);
};

export default useFetchHighlights;
