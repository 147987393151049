import { useDispatch } from "react-redux";

import { useLogout } from "~common/services";
import { resetBankStateHard } from "~common/store/slices/bankLinking-slice";
import { paymentInstrumentsActions } from "~common/store/slices/paymentInstruments-slice";
import { useTracking } from "~common/tracking";
import MobileAppEventBus from "~common/utils/mobile-app-messaging";
import { setHighlights } from "~src/store/slices/highlights-slice";
import { consumerActivitiesActions } from "~src/store/slices/services/consumerActivities-slice";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";
import { merchantsActions } from "~src/store/slices/services/merchants-slice";
import { userDataActions } from "~src/store/slices/services/userData-slice";
import { userRewardsActions } from "~src/store/slices/services/userRewards-slice";
import {
  clearAuthSession,
  clearAuthState,
  clearClaims,
  setAuthPhone,
} from "~src/store/slices/user-slice";

const useAppLogout = () => {
  const dispatch = useDispatch();
  const { trackEvent, captureException, clearUserTraits } = useTracking();
  const { mutate: serviceLogout } = useLogout();

  const clearState = () => {
    dispatch(setAuthPhone(null));
    dispatch(clearAuthState());
    dispatch(clearAuthSession());
    dispatch(clearClaims());
    dispatch(resetBankStateHard());
    dispatch(setHighlights([]));

    // Reset service slices so that a different user
    // can log in without refreshing the page and will
    // see their own data, not the previous user's data.
    for (const serviceActions of [
      consumerActivitiesActions,
      currentUserActions,
      merchantsActions,
      paymentInstrumentsActions,
      userDataActions,
      userRewardsActions,
    ]) {
      dispatch(serviceActions.resetState());
    }
  };

  const dispatchLogoutEvent = () => {
    // PD-256 Remove old method of sending events
    window.dispatchEvent(new CustomEvent("CATCH_LOGOUT"));
    const eventBus = new MobileAppEventBus({ overrideTargetToWindow: true });
    eventBus.sendEventToMobile("CATCH_LOGOUT");
  };

  const logout = async (eventData: Record<string, unknown>) => {
    trackEvent("Logging out", eventData);
    try {
      await serviceLogout(undefined);
      clearState();
      clearUserTraits();
      dispatchLogoutEvent();
    } catch (rawError) {
      captureException({
        component: "useAppLogout",
        exceptionMessage: "Error logging out",
        rawError,
      });
    }
  };

  return logout;
};

export default useAppLogout;
