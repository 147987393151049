import { useGet } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";

const useGetConstants = () =>
  useGet<
    { purchase_amount_earned_referral_threshold: number },
    ErrorResponseData,
    undefined,
    undefined
  >({
    path: "users-svc/constants",
  });

export { useGetConstants };
