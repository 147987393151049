import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { Highlight, HighlightWithState } from "~common/services";

type HighlightsState = {
  highlights: (Highlight | HighlightWithState)[] | null;
  loading: boolean;
};

const initialState: HighlightsState = {
  highlights: null,
  loading: true,
};

const slice = createSlice({
  name: "highlights",
  initialState,
  reducers: {
    setHighlights: (
      state,
      action: PayloadAction<(Highlight | HighlightWithState)[]>
    ) => {
      state.highlights = action.payload;
    },
    setFilteredHighlights: (state, action: PayloadAction<Highlight["id"]>) => {
      state.highlights =
        state.highlights?.filter(
          (highlight) => highlight.id !== action.payload
        ) || [];
    },
    setUpdatedHighlights: (
      state,
      action: PayloadAction<Highlight | HighlightWithState>
    ) => {
      state.highlights =
        state.highlights?.map((highlight) =>
          highlight.id === action.payload.id ? action.payload : highlight
        ) || [];
    },
    setHighlightsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setHighlights,
  setFilteredHighlights,
  setUpdatedHighlights,
  setHighlightsLoading,
} = slice.actions;
export default slice.reducer;
