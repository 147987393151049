import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTrackPageView } from "~common/tracking";
import CatchCardBanner from "~src/components/catch-card/CatchCardBanner";
import HighlightsSection from "~src/components/highlights/HighlightsSection";
import LargePagePanel from "~src/components/layout/LargePagePanel";
import AppAdoptionNux from "~src/components/rewards/AppAdoptionNux";
import ExploreCategories from "~src/components/shop/ExploreCategories";
import RewardClaimedModal from "~src/components/shop/RewardClaimedModal";
import useFetchHighlights from "~src/hooks/fetching/useFetchHighlights";
import useFetchNuxs from "~src/hooks/fetching/useFetchNuxs";
import useClaimRewards from "~src/hooks/useClaimRewards";
import { selectCatchCard, selectClaims, selectCurrentUser } from "~src/store";
import {
  setClaimedCampaign,
  setClaimedCampaignId,
} from "~src/store/slices/user-slice";

const Shop: React.VFC = () => {
  const dispatch = useDispatch();
  const catchCard = useSelector(selectCatchCard);
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);
  const currentUser = useSelector(selectCurrentUser.data);

  useTrackPageView("Shop");
  useFetchNuxs();
  useClaimRewards();
  useFetchHighlights();

  useEffect(() => {
    if (
      newUserOnboardingRewardCampaign &&
      !newUserOnboardingRewardCampaign.claimed &&
      catchCard?.user_flow_status === "approved"
    ) {
      dispatch(
        setClaimedCampaignId(newUserOnboardingRewardCampaign.reward_campaign_id)
      );
      dispatch(
        setClaimedCampaign({
          ...newUserOnboardingRewardCampaign,
          is_second_chance_bonus: true,
        })
      );
    }
  }, [dispatch, newUserOnboardingRewardCampaign, catchCard]);

  return (
    <>
      <RewardClaimedModal />
      <AppAdoptionNux />

      <LargePagePanel
        sx={{
          overflowX: "hidden",
          "& > div": {
            px: {
              zero: 4,
              md: 8,
              lg: 6,
            },
          },
        }}
      >
        {currentUser?.is_eligible_for_catch_pass && <CatchCardBanner />}
        <HighlightsSection />
        <ExploreCategories utmCampaign="web_app" />
      </LargePagePanel>
    </>
  );
};

export default Shop;
