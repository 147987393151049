import React from "react";

import { Box, ButtonBase, Typography } from "@mui/material";

import {
  HighlightIcon as HighlightIconTypes,
  HighlightStatus,
} from "~common/services";

type HighlightIconProps = {
  icon: HighlightIconTypes;
  onClick: () => void;
  status: HighlightStatus;
};

const HighlightIcon: React.VFC<HighlightIconProps> = ({
  icon,
  onClick,
  status,
}) => (
  <ButtonBase
    disableRipple
    onClick={onClick}
    sx={({ palette, shape }) => ({
      width: 104,
      color: palette.grey[600],
      fontFamily: "inherit",
      flexDirection: "column",
      flexShrink: 0,
      "&.Mui-focusVisible": {
        borderRadius: `${shape.borderRadius}px`,
        outline: "2px solid",
        outlineColor: palette.info.main,
      },
    })}
  >
    <Box
      sx={({ palette, spacing }) => ({
        borderRadius: "50%",
        border: `2px solid ${
          status === "unread" ? palette.primary.main : palette.grey[400]
        }`,
        padding: spacing(1.5),
        overflow: "hidden",
        opacity: status === "completed" ? 0.3 : 1,
        transition: "transform 0.12s linear",
        "&:hover, .Mui-focusVisible &": {
          transform: "scale(1.0667) translateZ(0)",
        },
      })}
    >
      <Box
        component="img"
        src={icon.image}
        alt=""
        sx={({ palette }) => ({
          width: 74,
          height: 74,
          display: "block",
          border: `1px solid ${palette.grey[300]}`,
          borderRadius: "inherit",
        })}
      />
    </Box>

    <Box
      sx={{
        width: "100%",
        marginTop: 2,
        opacity: status === "completed" ? 0.3 : 1,
      }}
    >
      <Typography
        component="div"
        variant="h4"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {icon.header}
      </Typography>

      <Typography variant="bodySmall">{icon.subheader}</Typography>
    </Box>
  </ButtonBase>
);

export default HighlightIcon;
