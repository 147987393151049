import { configureStore } from "@reduxjs/toolkit";

import bankLinking from "~common/store/slices/bankLinking-slice";
import experimentation from "~common/store/slices/experimentation-slice";
import paymentInstruments from "~common/store/slices/paymentInstruments-slice";

import core from "./slices/core-slice";
import highlights from "./slices/highlights-slice";
import nuxs from "./slices/nuxs-slice";
import consumerActivities from "./slices/services/consumerActivities-slice";
import currentUser from "./slices/services/currentUser-slice";
import merchants from "./slices/services/merchants-slice";
import rewardPoolEntries from "./slices/services/rewardPoolEntries-slice";
import rewardPoolEvents from "./slices/services/rewardPoolEvents-slice";
import rewardPools from "./slices/services/rewardPools-slice";
import userData from "./slices/services/userData-slice";
import userRewards from "./slices/services/userRewards-slice";
import user from "./slices/user-slice";

const store = configureStore({
  reducer: {
    // User Portal v2 slices:
    core,
    consumerActivities,
    currentUser,
    highlights,
    merchants,
    nuxs,
    userData,
    userRewards,
    user,
    rewardPools,
    rewardPoolEntries,
    rewardPoolEvents,
    // Common Library slices:
    paymentInstruments,
    bankLinking,
    experimentation,
  },
});

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;

export default store;
export type { AppDispatch, RootState };
