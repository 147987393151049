// GENERATED FILE. DO NOT EDIT.

import React from "react";

import IconSVG from "../vector-source/Claim.icon.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const ClaimIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon iconSvg={IconSVG} {...props} />
);

export default ClaimIcon;
