import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Cancel as CancelIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { DefaultIconButton } from "~common/components/controls/buttons";
import { CatchCashIcon } from "~common/components/icons/vector";
import MarqetaCard from "~common/components/marqeta/MarqetaCard";
import { embeddedMessenger } from "~common/utils/iframe-messaging";
import { IS_DEV } from "~src/config";

import { selectCatchCard } from "../../store";

const MiniCatchCard: React.VFC = () => {
  const [searchParams] = useSearchParams();
  const catchCard = useSelector(selectCatchCard);
  const referer = searchParams.get("referer") || document.referrer;

  const handleClose = () => {
    const messenger = embeddedMessenger(referer);
    messenger.dispatch("CATCH_TOFU_BACK", {});
    messenger.destroy();
  };

  return (
    <Stack
      pt={3}
      sx={({ palette, shape }) => ({
        backgroundColor: palette.tertiary.main,
        height: "100%",
        borderTopLeftRadius: `${shape.borderRadius * 4}px`,
        borderTopRightRadius: `${shape.borderRadius * 4}px`,
        alignItems: { zero: "flex-start", sm: "center" },
      })}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        px={3}
        color="white"
      >
        <CatchCashIcon size="icon" sx={{ width: 14, height: 14 }} />

        <Typography variant="bodySmall">
          <strong>Earn 6%</strong> by paying with CatchPass.
        </Typography>
      </Stack>

      <DefaultIconButton
        onClick={handleClose}
        size="medium"
        aria-label="Close dialog"
        sx={({ palette, spacing }) => ({
          color: palette.common.white,
          position: "absolute",
          top: 10,
          right: 10,
        })}
      >
        <CancelIcon />
      </DefaultIconButton>

      <MarqetaCard
        token={catchCard?.marqeta_token || ""}
        isSandbox={IS_DEV}
        isShort
      />
    </Stack>
  );
};

export default MiniCatchCard;
